<template>
  <div>
    <user-list-top-nav />

    <div>
      <v-data-table
        :items="reportedUsersList"
        :pagination.sync="pagination"
        :loading="isLoading" 
        :total-items="total"
        :rows-per-page-items="[10, 25, 50, 100]"
        class="elevation-1"
        :headers-length="1"
      >
        <template slot="items" slot-scope="props">
          <td>
            <router-link
              :to="`/moderation/user_list/user/${props.item.who_got_report.id}`"
              class="blue-grey--text bold link-hover"
            >
              {{ props.item.who_got_report.username }}
            </router-link>
          </td>
        </template>
      </v-data-table>

      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          @input="refreshPagination"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UserListTopNav from "../UserListTopNav"
import SavesPagination from "../../../../../mixins/SavesPaginationMixin"
import { fetchData } from "../../../../../utils/response"
import { getReportedUsersRequest } from "../../../../../api/users"
export default {
  name: "ReportedUsersList",
  components: {
    UserListTopNav
  },
  mixins: [SavesPagination],
  data() {
    return {
      reportedUsersList: [],
      pagination: {},
      isLoading: false,
      total: 0,
      select: null,
      search: null
    }
  },
  computed: {
    pages() {
      if (this.pagination.rowsPerPage == null || this.total == null) return 0

      return Math.ceil(this.total / this.pagination.rowsPerPage)
    }
  },
  watch: {
    /**
     * Watch pagination changes and load more data
     */
    pagination(pagination) {
      this.$paginationCacheSave(pagination.page, pagination.rowsPerPage)
      this.refresh()
    }
  },
  mounted() {
    const pagination = this.$paginationCacheGet()
    this.pagination.page = pagination.page
    this.pagination.rowsPerPage = pagination.per_page
  },
  created() {
    this.$bus.on("user-sort-by", this.onSelectUsersBy)
    this.$bus.on("user-search-input", this.onSearchUser)
  },
  destroyed() {
    this.search = null
    this.$bus.off("user-sort-by", this.onSelectUsersBy)
    this.$bus.off("user-search-input", this.onSearchUser)
  },
  methods: {
    async refresh() {
      const params = {
        page: this.pagination.page,
        per_page: this.pagination.rowsPerPage,
        order_by: this.select,
        search: this.search
      }
      this.isLoading = true
      // log(params, "paramsparamsparams");
      this.reportedUsersList = await getReportedUsersRequest(params)
        .then(response => {
          this.total = response.data.meta.total
          return response
        })
        .then(fetchData)
        .then(data =>
          data.map(user => {
            return user
          })
        )
        .catch(this.$notificationHandleResponseError)

      this.isLoading = false
    },
    refreshPagination() {
      this.refresh()
      this.$paginationCacheSave(
        this.pagination.page,
        this.pagination.rowsPerPage
      )
    },
    onSelectUsersBy(sortUserSelectValue) {
      this.select = sortUserSelectValue
      this.refresh()
    },
    onSearchUser(searchUserValue) {
      this.search = searchUserValue
      this.refresh()
    }
  }
}
</script>

<style scoped>
.link-hover:hover {
  color: #fff !important;
  text-decoration: none !important;
}
</style>
